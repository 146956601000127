import ReactMaterialComponentBase from "../../Shared/ReactMaterialComponentBase"
import React from "react"
import { BookForm } from "../React/BookForm"

export default class CreateBook extends ReactMaterialComponentBase {
  static get observedAttributes() {
    return ["target"]
  }

  constructor() {
    super()

    this.jsxRootComponent = () => <BookForm createBook={(book) => this.create(book)} />
  }

  /**
   * @param {import("../Types").Book} book
   */
  async create(book) {
    try {
      const token = await window.authorise()

      const createdBook = await fetch(`${this.getAttribute("target")}`, {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
        body: JSON.stringify(book),
      })

      if (!createdBook.ok) {
        throw Error("Failed to create book")
      }

      this.onSuccess && this.onSuccess("Book created")
    } catch (error) {
      this.onError && this.onError("Failed to create recipe")
    }
  }
}

customElements.define("create-book", CreateBook)
