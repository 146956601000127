import { Button, Fab, FormControl, FormGroup, FormHelperText, TextField, Tooltip, Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { Add, Edit } from "@material-ui/icons"
import { ImageDisplay } from "./ImageList"
import { ImageUploader } from "./ImageSelector"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import LuxonUtils from "@date-io/luxon"
import { useRef } from "react"
import { DateTime } from "luxon"

function removeBookImage(book, image, setBook) {
  const bookImages = book.bookImages || []

  book.bookImages = bookImages.filter((ri) => ri.fileName !== image.fileName)

  setBook({ ...book })
}

/**
 *
 * @param {{
 * createBook?: (book: import("../Types").Book) => void,
 * updateBook?: (book: import("../Types").Recipe, onComplete: () => Promise<void>) => void
 * book?: import("../Types").Book
 * }} props
 * @returns {JSX.Element}
 */
export const BookForm = (props) => {
  /**
   * @type {Partial<import("../Types").Book>}
   */
  const initialBookState = props.book || {}

  const [book, setBook] = useState(initialBookState)

  const ref = useRef(null)

  useEffect(() => {
    setBook(props.book || {})
  }, [props.book])

  return (
    <FormGroup innerRef={ref}>
      <FormControl margin="normal">
        <TextField
          variant="outlined"
          label="Book Name"
          value={book.name || ""}
          disabled={!!props.book}
          onChange={(event) => setBook({ ...book, name: event.target.value })}
        />
        <FormHelperText>The name of the book</FormHelperText>
      </FormControl>
      <FormControl margin="normal">
        <MuiPickersUtilsProvider utils={LuxonUtils}>
          <DatePicker
            views={["year", "month"]}
            inputVariant="outlined"
            value={book.date || null}
            format="yyyy-MM"
            onChange={(date) => setBook({ ...book, date: (date && date.toFormat("yyyy-MM")) || null })}
            variant="inline"
            orientation="landscape"
            label="Book Date"
            PopoverProps={{ container: ref.current, disablePortal: true }}
          />
        </MuiPickersUtilsProvider>
        <FormHelperText>The date of the book</FormHelperText>
      </FormControl>
      <FormControl margin="normal" style={{ width: "100%" }}>
        <div style={{ display: "block", overflow: "hidden", overflowX: "auto", whiteSpace: "nowrap" }}>
          {(book.bookImages || []).map((item, index) => {
            return (
              <div key={index} style={{ display: "inline-block", paddingRight: 20 }}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <ImageDisplay thumbnail={true} file={item} />
                  <Button onClick={() => removeBookImage(book, item, setBook)}>CLEAR</Button>
                </div>
              </div>
            )
          })}
        </div>
        <FormHelperText>Add pictures of the book</FormHelperText>
      </FormControl>
      <FormControl margin="normal">
        <ImageUploader
          onUploaded={({ fileName, thumbFileName }) => {
            setBook({ ...book, bookImages: [...(book.bookImages || []), { fileName, thumbFileName }] })
          }}
        />
      </FormControl>
      <FormControl margin="normal" style={{ alignItems: "flex-start" }}>
        <Tooltip title={<Typography>{props.book ? "Update Book" : "Create Book"}</Typography>}>
          <Fab
            color="primary"
            variant="extended"
            onClick={() => {
              if (props.createBook) {
                props.createBook(book)
              } else if (props.updateBook) {
                props.updateBook(book)
              }
              setBook(initialBookState)
            }}
          >
            {props.book ? (
              <>
                <Edit /> Update
              </>
            ) : (
              <>
                <Add /> Create
              </>
            )}
          </Fab>
        </Tooltip>
      </FormControl>
    </FormGroup>
  )
}
